import React from 'react';

function Homily() {

    
    return (
        <div className='SundayHomily'>
            <main>
                <a className='MainBTN' href={`/assets/Homily/H2024-11-3.pdf`} download>
                📄Read Homily  
                <p>11/03/2024</p>
                </a>

                <a className='MainBTN' href={`/assets/Bulletin/2024/2024-11-3.pdf`} download>📄View Bulletin 
                <p>11/03/2024</p>
                 </a>
              

            </main>

        </div>
    );
}

export default Homily;