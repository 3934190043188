import React from 'react'

function FeedBack(props) {
  return (
    <div className='FeedBack-Container'>
        <header>
        <h1>Feed Back</h1>
        {/* <p>We would love your feedback.</p> */}
        <p>Did you enjoy our website?</p>
        </header>
        <main>
            <a href="https://forms.office.com/r/YJMrvTKrgc">
            <button>No</button>
            </a>
            <a href="https://forms.office.com/r/YJMrvTKrgc">
            <button>Yes</button>
            </a>
        </main>
        <aside>
            <p>Website  by <a href="https://www.lcwebsitesolutions.com/" target="blank"> LC Website Solutions</a></p>
         
      

        </aside>

        <div>
       
        </div>
      
    </div>


  )
}


export default FeedBack
