import React from 'react'




const Bulletin = props => {
    return (
        <div className='Bulletin-Container NoBars'>
            <h2>Bulletin and Announcements</h2>

            <ul className='NoBars'>   
                        

                <li>
                    {/* <img src="/assets/images/Curillo.png" alt="" /> */}

                    
                <aside  style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }} >

                <a href="/assets/images/Curillo.png" target='blank'>View</a>
                    <h3>Cursillo Spiritual Retreat</h3>
                    </aside>

                </li>
            

            </ul>

        </div>
    )
}

export default Bulletin