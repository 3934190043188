import React, {useState, useEffect} from 'react'


function MainNav(props) {


  const [MainMenu] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // const MainMenuHandler = e => {
  //   console.log("button was clicked");
  //   setMainMenu(prevMainMenu => !prevMainMenu);
  // };


  return (
    <div className='MainNav-Component'  style={{
    width: MainMenu ? '100%' : windowWidth > 600 ? '15%' : '100%',
    transition: MainMenu ? "0.5s ease-in-out" : "0.5s ease-in-out"
    }}>

      {/* <header>
        <img  onClick={MainMenuHandler} src="/assets/IconMenu.png" alt="" />
        <h1 onClick={MainMenuHandler}>Welcome</h1>
      </header> */}

      <div style={{display: MainMenu ? "grid": "none"}}>
      <main className='NavList-Container'>
        <ul>
          <li>
            <a href="/parish-staff">Parish Staff</a>
          </li>
          <li>
            <a href="/our-ministries">Our Ministries</a>
          </li>

          <li>
            <a href="/faith-formation">Faith Formation</a>
          </li>
          <li>
            <a href="/gift-shop">Gift Shop & Books</a>
          </li>
       
        </ul>

        
      </main>

      <aside>
        <ul className='contactUs-container'>
          {/* <li>
            <div>
            <p>Phone Number:</p>
            <p>(270) 640-9850</p>
            </div>
            <img src="/assets/iconPhone.png" alt="" />
          </li> */}
          <li>
            <div>
            <p>Address:</p>
            <p>448 State Line Rd Oak Grove KY 42262</p>

            </div>
            <a href="http://maps.google.com/?q=448 State Line Rd Oak Grove KY 42262" target='blank'>
            <img src="/assets/iconMapPin.png" alt=""  />
            </a>
          </li>
        </ul>

      </aside>
      </div>
     
    </div>
  )
}


export default MainNav
