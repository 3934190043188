import React from 'react'

function SafeEnvironment(props) {
  return (
    <div className='SafeEnvironment'>
        <h1>Safe Environment</h1>
        <p>Our diocese is committed to keeping our community safe. Please visit our <a href="https://owensborodiocese.org/safe-environment-policies/" target='blank'>Safe Environment Page</a> to learn more about the steps we are taking to ensure the community safety.</p>

        <a className='MainBTN' href="https://owensborodiocese.org/safe/ " target='blank'>Learn more or report abuse</a>
        
        
    </div>
  )
}

SafeEnvironment.propTypes = {}

export default SafeEnvironment
