import React from 'react'

function BackHome(props) {
  return (
    <div className='BackHomeNav-Container'>
        <a href="/">
        <img src="/assets/iconBackBTN.png" alt="" />
        </a>
        
    </div>
  )
}


export default BackHome
