import React from 'react'

function Dashboard(props) {
  return (
    <div>Dashboard</div>
  )
}

Dashboard.propTypes = {}

export default Dashboard
