import React, {useState}from 'react'

function Announcements(props) {

  const [calendarOptions, setCalendarOptions] = useState(false);

  const [outlookEventLink, setOutlookEventLink ] = useState();
  const [gmailEventLink, setGmailEventLink] = useState ();
  const [yahooEventLink, setYahooEventLink] = useState();
  const [officeEventLink, setOfficeEventLink] = useState();

  const [eventName] = useState("stateEventName");



  
  



  const AddToCalender = e =>{
    console.log(e.target.id)
    setCalendarOptions(true);

    console.log("event name:", eventName)

    setGmailEventLink(`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20230211T000000Z%2F20230218T010000Z&details=description&location=448%20State%20Line%20Rd%20Oak%20Grove%20KY%2042262&text=${eventName}`);


  setOutlookEventLink(`https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=description&enddt=2023-02-18T01%3A00%3A00%2B00%3A00&location=448%20State%20Line%20Rd%20Oak%20Grove%20KY%2042262&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-02-11T00%3A00%3A00%2B00%3A00&subject=${eventName}`);


  setYahooEventLink(`https://calendar.yahoo.com/?desc=description&dur=&et=20230218T010000Z&in_loc=448%20State%20Line%20Rd%20Oak%20Grove%20KY%2042262&st=20230211T000000Z&title=${eventName}`);


  setOfficeEventLink(`https://outlook.office.com/calendar/0/deeplink/compose?allday=false&body=description&enddt=2023-02-18T01%3A00%3A00%2B00%3A00&location=448%20State%20Line%20Rd%20Oak%20Grove%20KY%2042262&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2023-02-11T00%3A00%3A00%2B00%3A00&subject=${eventName}`);


  }






 

  return (
    <div className='Announcements-ViewPage'>
       <header>
      <h1>Parish Ministries</h1>
      </header>

      <main>
      <ul className='NoBars'>
              <li>
                <header>
                <h2>CCD</h2>
                <p>Every Sunday at 10:00 AM</p>
                </header>
              </li>


              <li>
              <header>
              <h2>RCIA</h2>
              <p>Every Wednesday at 7:00 PM</p>
              </header>
              </li>

              
              <li>
              <header>
              <h2>Pray Group</h2>
              <p>Every Tuesday 9:00 AM</p>
              </header>


              <main>
                <p>All are welcome to come join the Prayer Group. Prayer Group meet in the church.</p>
              </main>
              </li>


              <li>
              <header>
              <h2>Music Ministry</h2>
              <p>Sundays</p>
              </header>


              <main>
                <p>Choir practice will be 30 minutes before each mass. If you're interested in joining the choir, please contact the Music Director, Dr. David Goldblatt. You may email him at goldblattd@apsu.edu</p>
              </main>
              </li>

            
          </ul>
      </main>

      {/* <header>
      <h1>Parish Events</h1>
      <p>2</p>
      </header>

      <main>
          <ul className='NoBars'>

           

              <li>
                <header>
                <h2>Titans 5K Run</h2>
                <p><b>Date:</b> Saturday August 19 2023 </p>
                <p><b>Details:</b> Nashville event $50 registration fee. Meeting at Nashville</p>
                </header>
                <main>
                  <a href=" https://www.tennesseetitans.com/5k/">
                  <button>RSVP</button>
                  </a>

                  <button onClick={AddToCalender}>Add to Calender</button>
                </main>
              </li>


                            
        
          </ul>
        

      </main> */}

     

      <aside style={{display: calendarOptions ? "grid" : "none",
                    left: calendarOptions ? "0px" : "-300px",
                    }}>

                <h2>Selected your calender</h2>

              
          

                <a href={gmailEventLink} onClick={AddToCalender}  title="Save Event in my Calendar" >Gmail
                </a>
                <a href={outlookEventLink} onClick={AddToCalender} title="Save Event in my Calendar">Outlook
                </a>

                <a href={yahooEventLink} onClick={AddToCalender} title="Save Event in my Calendar" >Yahoo
                </a>

                <a href={officeEventLink} onClick={AddToCalender} title="Save Event in my Calendar" >Office
                </a>

                
          <button  onClick={AddToCalender} > close</button>


                
          </aside>

    </div>
  )
}


export default Announcements
