import React from 'react'
import BackHome from '../components/LandingPage/BackHome'

function UnderDevelopment(props) {
  return (
    <div className='UnderDevelopment-PageView'>
        <BackHome></BackHome>
        <p>This page is Under Development please come back soon</p>
    </div>
  )
}


export default UnderDevelopment
