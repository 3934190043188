import React from 'react'
import HeroBanner from './LandingPage/HeroBanner'

function SummerEvents(props) {
  return (
    <div className='SummerEvents'>
        <HeroBanner></HeroBanner>
        <h1>Summer Events 2023</h1>
          <ul className='NoBars'>
     
              <li>
                <header>
                <h2>Water Fun Day</h2>
                <p><b>Date:</b> Sunday June 25th 2023</p>
                <p><b>Details:</b> Free event after the 11:30 am Mass</p>
                </header>
                <main>
                  <a className="MainBTN" href="https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=mqlAew8yIxzaJ8HDv3wvPg8eZtHCHTgi&id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__lqWrRdUN09URUlPN01DWjQxWjNJUU1GRTNHOFUzSi4u">
                  View RSVP Results
                  </a>

                  {/* <button onClick={AddToCalender}>Add to Calender</button> */}
                </main>
              </li>



              <li>
                <header>
                <h2>Mammoth Cave/Lincoln</h2>
                <p><b>Date:</b> Tuesday July 25th 2023</p>
                <p><b>Details:</b> $10 per person Ages 10+</p>
                </header>
                <main>
                  <a className="MainBTN" href="https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=mqlAew8yIxzaJ8HDv3wvPg8eZtHCHTgi&id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__lqWrRdURTRLNFRLMUVFTEhSSlBUWFFUSTM0SkdCVi4u">
                  View RSVP Results
                  </a>

                  {/* <button onClick={AddToCalender}>Add to Calender</button> */}
                </main>
              </li>


              
              <li>
                <header>
                <h2>Kentucky Kingdom</h2>
                <p><b>Date:</b> Saturday July 29th 2023</p>
                <p><b>Details:</b> $20 per person Ages 13-18</p>
                </header>
                <main>
                  <a className="MainBTN" href="https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=mqlAew8yIxzaJ8HDv3wvPg8eZtHCHTgi&id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__lqWrRdUOFhTWVVOWTBYSTQ0S1A5Wk5LTUdONzVRTy4u">
                  View RSVP Results
                  </a>

                  {/* <button onClick={AddToCalender}>Add to Calender</button> */}
                </main>
              </li>



              <li>
                <header>
                <h2>Venture River Water Park</h2>
                <p><b>Date:</b> Saturday August 26th 2023</p>
                <p>Details: $10 per person All Ages</p>
                </header>
                <main>
                  <a className="MainBTN" href="https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=mqlAew8yIxzaJ8HDv3wvPg8eZtHCHTgi&id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__lqWrRdUQ0VCMlpQOUdRTVdKMTFPTE1ONVQxOTlNWS4u">
                  View RSVP Results
                  </a>

                  {/* <button onClick={AddToCalender}>Add to Calender</button> */}
                </main>
              </li>


              <li>
                <header>
                <h2>Titans 5K Run</h2>
                <p><b>Date:</b> Saturday August 19 2023 </p>
                <p><b>Details:</b> Nashville event $50 registration fee. Meeting at Nashville</p>
                </header>
                
              </li>


                   
        
          </ul>
    </div>
  )
}


export default SummerEvents
